import {
  Flex,
  Table,
  Button,
  Input,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  Column,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
} from "@tanstack/react-table";
import { utils, writeFileXLSX } from "xlsx";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import moment from "moment-timezone";

// Assets
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [loading, setLoading] = React.useState(false);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  function formataCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  function createXls(data, filename) {
    const newData = data.map((value) => {
      setLoading(true);
      return {
        IDTitulo: value.idtitulo,
        Status: value.statusparcela,
        Participante: value.codparticipante,
        Condomínio: value.condominiodescricao,
        Unidade: value._unidade,
        CPF:
          value.clientecpfcnpj?.length == 11
            ? formataCPF(value.clientecpfcnpj)
            : value.clientecpfcnpj,
        Devedor: value.clientenome,
        Tipo: value.tipotitulo,
        Vencimento: {
          t: "d",
          v: moment(value._vencimento).add(30, "minute").utc().format(),
        },
        Atraso: value._diasatraso,
        Principal: value.principal,
        Multa: value.multavalor,
        Juros: value.jurosvalor,
        Correção: value.moravalor,
        Honorários: value.honorariovalor,
        "Valor atualizado": value.totalcorrigido,
        Operação: value.operacaodescricao,
        "Data Operação": value._data
          ? {
              t: "d",
              v: moment(value._data).add(30, "minute").utc().format(),
            }
          : "",
        "Valor Investido": value._valorinvestido,
        "Juros PRIME": value._6pbank_jurosprime,
        "Data liquidação": value._pagamento
          ? {
              t: "d",
              v: moment(value._pagamento).add(30, "minute").utc().format(),
            }
          : "",
        "Valor liquidação": value._valorpago,
        "Receita do título": value.receitatitulo,
        "Data P. Contas": value._pcontasdata
          ? {
              t: "d",
              v: moment(value._pcontasdata).add(30, "minute").utc().format(),
            }
          : "",
        "Valor P. Contas": value._pcontasvalor,
        "Data de Abertura": value._dataabertura,
        "Numero do Processo": value._cryptnumeroprocesso,
        "Status Processo": value.statusprocessodescricao,
        "Número do Acordo": value.numeroacordo,
      };
    });
    const ws = utils.json_to_sheet(newData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);

    const time = moment().format("DD-MM-YYYY_HH-mm");

    writeFileXLSX(wb, `${filename}_${time}.xlsx`);
    setLoading(false);
  }

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const redTextColor = useColorModeValue("red.600", "white");
  const yellowTextColor = useColorModeValue("yellow.600", "white");
  const greenTextColor = useColorModeValue("green.600", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Card minW="max-content" px="0px">
      <Flex direction="Row">
        <Flex direction="column" borderRightWidth={1}>
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Informações do credor
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.slice(0, 6).map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row
                      .getVisibleCells()
                      .slice(0, 6)
                      .map((cell, index) => {
                        let value = cell.getValue();
                        let data;
                        if (cell.column.id == "clientecpfcnpj") {
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value?.length == 11 ? formataCPF(value) : value}
                            </Text>
                          );
                        } else if (cell.column.id == "statusparcela") {
                          let value = cell.getValue();
                          data =
                            value.toUpperCase() == "LIQUIDADO" ? (
                              <Text
                                color={greenTextColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                LIQUIDADO
                              </Text>
                            ) : value.toUpperCase() == "A VENCER" ? (
                              <Text
                                color={yellowTextColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                À VENCER
                              </Text>
                            ) : value.toUpperCase() == "VENCIDO" ? (
                              <Text
                                color={redTextColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                VENCIDO
                              </Text>
                            ) : (
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                -
                              </Text>
                            );
                        } else
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? value : "-"}
                            </Text>
                          );
                        return (
                          <Td
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex direction="column" borderRightWidth={1}>
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Informações do título
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.slice(6, 10).map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row
                      .getVisibleCells()
                      .slice(6, 10)
                      .map((cell, index) => {
                        let value = cell.getValue();
                        let data;
                        if (cell.column.id == "_vencimento")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {moment(value).format("DD/MM/YYYY")}
                            </Text>
                          );
                        else if (cell.column.id == "principal")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? value : "-"}
                            </Text>
                          );
                        return (
                          <Td
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex direction="column" borderRightWidth={1}>
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Correção do título
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.slice(10, 15).map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row
                      .getVisibleCells()
                      .slice(10, 15)
                      .map((cell, index) => {
                        let value = cell.getValue();
                        let data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {formatter.format(value)}
                          </Text>
                        );
                        return (
                          <Td
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex direction="column" borderRightWidth={1}>
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Informações da Operação
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.slice(15, 21).map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row
                      .getVisibleCells()
                      .slice(15, 21)
                      .map((cell, index) => {
                        let value = cell.getValue();
                        let data;
                        if (cell.column.id == "_data")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? moment(value).format("DD/MM/YYYY") : "-"}
                            </Text>
                          );
                        else if (cell.column.id == "_valorinvestido")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else if (cell.column.id == "_6pbank_jurosprime")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? value : "-"}
                            </Text>
                          );
                        return (
                          <Td
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex direction="column" borderRightWidth={1}>
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Informações da liquidação
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.slice(21, 26).map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row
                      .getVisibleCells()
                      .slice(21, 26)
                      .map((cell, index) => {
                        let value = cell.getValue();
                        let data;
                        if (cell.column.id == "_pagamento")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? moment(value).format("DD/MM/YYYY") : "-"}
                            </Text>
                          );
                        else if (cell.column.id == "_valorpago")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else if (cell.column.id == "_pcontasdata")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? moment(value).format("DD/MM/YYYY") : "-"}
                            </Text>
                          );
                        else if (cell.column.id == "_pcontasvalor")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else if (cell.column.id == "receitatitulo") {
                          const principalValue = row
                            .getVisibleCells()[8]
                            .getValue();
                          const liquidValue = row
                            .getVisibleCells()[21]
                            .getValue();
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {liquidValue - principalValue >= 0
                                ? formatter.format(liquidValue - principalValue)
                                : "-"}
                            </Text>
                          );
                        } else
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? value : "-"}
                            </Text>
                          );
                        return (
                          <Td
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex direction="column">
          <Flex px="25px" justify="center" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Jurídico
            </Text>
          </Flex>
          <Table variant="simple" color="gray.500">
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup, index) => (
                <Tr key={index}>
                  {headerGroup.headers.slice(26, 29).map((header, index) => (
                    <Th pe="10px" key={index} borderColor={borderColor}>
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                        onClick={header.column.getToggleSortingHandler()}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {tableInstance.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    backgroundColor={
                      index % 2 == 0 ? "blackAlpha.100" : "white"
                    }
                  >
                    {row
                      .getVisibleCells()
                      .slice(26, 29)
                      .map((cell, index) => {
                        let value = cell.getValue();
                        let data;
                        if (cell.column.id == "_pagamento")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? moment(value).format("DD/MM/YYYY") : "-"}
                            </Text>
                          );
                        else if (cell.column.id == "_valorpago")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else if (cell.column.id == "_pcontasdata")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? moment(value).format("DD/MM/YYYY") : "-"}
                            </Text>
                          );
                        else if (cell.column.id == "_pcontasvalor")
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formatter.format(value)}
                            </Text>
                          );
                        else if (cell.column.id == "receitatitulo") {
                          const principalValue = row
                            .getVisibleCells()[8]
                            .getValue();
                          const liquidValue = row
                            .getVisibleCells()[21]
                            .getValue();
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {liquidValue - principalValue >= 0
                                ? formatter.format(liquidValue - principalValue)
                                : "-"}
                            </Text>
                          );
                        } else
                          data = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {value ? value : "-"}
                            </Text>
                          );
                        return (
                          <Td
                            key={index}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
      <Flex pt="20px">
        <Button
          onClick={() => tableInstance.firstPage()}
          disabled={!tableInstance.getCanPreviousPage()}
        >
          {"<<"}
        </Button>
        <Button
          onClick={() => tableInstance.previousPage()}
          disabled={!tableInstance.getCanPreviousPage()}
        >
          {"<"}
        </Button>
        <Button
          onClick={() => tableInstance.nextPage()}
          disabled={!tableInstance.getCanNextPage()}
        >
          {">"}
        </Button>
        <Button
          onClick={() => tableInstance.lastPage()}
          disabled={!tableInstance.getCanNextPage()}
        >
          {">>"}
        </Button>
        <Text
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: "10px",
            marginRight: "10px",
          }}
          gap={"10px"}
        >
          {"Página"}
          <strong>
            {tableInstance.getState().pagination.pageIndex + 1} de{" "}
            {tableInstance.getPageCount().toLocaleString()}
          </strong>
        </Text>
        <Text>
          <Input
            type="number"
            defaultValue={tableInstance.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              tableInstance.setPageIndex(page);
            }}
          />
        </Text>
        <select
          value={tableInstance.getState().pagination.pageSize}
          onChange={(e) => {
            tableInstance.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
        {tableData.length > 0 && (
          <Button
            onClick={() => {
              setLoading(true);
              setTimeout(() => createXls(tableData, "GestaoDeTitulos"), 100);
            }}
            bgColor={borderColor}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Exportar Dados"}
          </Button>
        )}
      </Flex>
    </Card>
  );
}
